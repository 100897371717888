import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LandingHeader, Login } from '..';
import './Landing.scss';
import { AdaptiveSidenav, CookieBanner } from '../../reusable';
import { getExpiringLocalStorageItem } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { ForgivenPassword } from '../auth/forgiven-pass/ForgivenPassword';
import {
  BenefitsCorporation,
  BenefitsEmployee,
  BenefitsPlanet,
  CreateZircle,
  DownCircle,
  DownGreen,
  Globe2,
  LeftGreen,
  Level1,
  Level2,
  Level3,
  Level4,
  MailWhite,
  Principle1,
  Principle2,
  Principle3,
  Principle4,
  Principle5,
  RPG,
  Refresh,
  RightGreen,
  TelephoneWhite,
  TrustCompany,
  WezircleGreen,
  WezircleWhite,
} from '../../../img';
import { useScreenType } from '../../../hooks';

type Principle = {
  title: string;
  description: string;
};

enum Operation {
  ADD,
  SUBTRACT,
}

const Landing: React.FC = () => {
  const navigate = useNavigate();
  const screenType = useScreenType();
  const [showSideNav, setShowSideNav] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(true);
  const [showForgivenPassword, setShowForgivenPassword] = useState(false);
  const [currentPrinciple, setCurrentPrinciple] = useState(0);
  const [isTextCarrouselAnimating, setIsTextCarrouselAnimating] =
    useState(false);
  const principles: Principle[] = [
    {
      title: 'Zircle',
      description:
        'Generar círculos de confianza donde los usuarios puedan interactuar de forma segura y privada',
    },
    {
      title: 'Zero emissions',
      description:
        'Concienciar que extendiendo el ciclo de vida de los productos contribuimos a evitar nuevas emisiones',
    },
    {
      title: 'Comunidad',
      description:
        'Incrementar el sentimiento de pertenecer a una comunidad existente mediante la economía circular',
    },
    {
      title: 'Social',
      description:
        'Crear el escaparate donde puedan compartir productos y conectar los empleados',
    },
    {
      title: 'Reconocimiento',
      description:
        'Puntuar la contribución de los empleados y de la empresa a reducir el impacto medioambiental',
    },
  ];

  const creaZircleRef = useRef<HTMLDivElement>(null);
  const comoFuncionaRef = useRef<HTMLSpanElement>(null);
  const beneficiosRef = useRef<HTMLDivElement>(null);
  const principiosRef = useRef<HTMLDivElement>(null);
  const contactoRef = useRef<HTMLDivElement>(null);

  const userLoggedIn = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  useEffect(() => {
    const userData = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    if (userData) {
      userLoggedIn();
    }
  }, [userLoggedIn]);

  const getSideNav = () => {
    if (showSideNav) {
      return (
        <AdaptiveSidenav onBlur={() => setShowSideNav(false)}>
          {!showForgivenPassword ? (
            <Login
              clickOnForgivenPasswords={() => {
                setShowForgivenPassword(true);
              }}
              onLoginSuccess={userLoggedIn}
            />
          ) : (
            <ForgivenPassword
              goingBackToLogin={() => setShowForgivenPassword(false)}
            />
          )}
        </AdaptiveSidenav>
      );
    }
  };

  const getCookieBanner = () => {
    if (showCookieBanner) {
      return <CookieBanner onClose={() => setShowCookieBanner(false)} />;
    }
  };

  const getArrowFunciona = () => {
    if (screenType === 'desktop') {
      return RightGreen;
    } else {
      return DownGreen;
    }
  };

  const updatePrinciple = (operation: Operation) => {
    if (operation === Operation.ADD) {
      return setCurrentPrinciple(
        currentPrinciple < 4 ? currentPrinciple + 1 : 0
      );
    }
    if (operation === Operation.SUBTRACT) {
      return setCurrentPrinciple(
        currentPrinciple > 0 ? currentPrinciple - 1 : 4
      );
    }
  };

  const getTrustCompanyImage = () => {
    if (screenType === 'desktop') {
      return (
        <div className="confianza-image-container">
          <img src={TrustCompany} />
        </div>
      );
    }
  };

  const activateTextCarrouselAnimation = () => {
    setIsTextCarrouselAnimating(true);

    setTimeout(() => {
      setIsTextCarrouselAnimating(false);
    }, 500);
  };

  return (
    <>
      {showSideNav || showCookieBanner ? (
        <div className="sidenav-backdrop"></div>
      ) : undefined}
      <div className="landing-container">
        {getCookieBanner()}
        {getSideNav()}
        <LandingHeader
          refs={[
            creaZircleRef,
            comoFuncionaRef,
            beneficiosRef,
            principiosRef,
            contactoRef,
          ]}
        />
        <div className="intro-container">
          <span className="impulsa-text">
            Impulsa el cambio en tu comunidad hacia un mundo más sostenible
          </span>
          <span className="descubre-text">
            Descubre cómo Wezircle puede ayudarte
          </span>
        </div>

        <div className="como-funciona-container">
          <img
            alt="Flecha que va a crea tu zircle"
            src={DownCircle}
            onClick={() => creaZircleRef.current?.scrollIntoView()}
          />
          <div ref={creaZircleRef} className="crea-tu-zircle-container">
            <div className="text">
              <span className="title">
                Crea tu Zircle sumándote a la nueva economía circular del futuro
              </span>
              <span className="description">
                Wezircle es una plataforma de economía circular orientada a
                empresas, instituciones y en general grandes comunidades, que
                permite compartir, alquilar, vender o regalar productos con el
                objetivo de reducir el impacto medioambiental y promover un
                consumo responsable
              </span>
            </div>
            <div className="image">
              <img src={CreateZircle} />
            </div>
          </div>
          <span ref={comoFuncionaRef} className="como-funciona-title">
            ¿Cómo funciona?
          </span>
          <div className="steps-container">
            <div className="step-container">
              <div className="img-container">
                <img src={WezircleGreen} />
              </div>
              <div className="text-container">
                <span className="title">Crea tu Zircle</span>
                <span className="description">
                  Solo pueden acceder las personas de tu organización, de forma
                  totalmente segura.
                </span>
              </div>
            </div>
            <div className="arrow-container">
              <img src={getArrowFunciona()} />
            </div>
            <div className="step-container">
              <div className="img-container">
                <img src={Refresh} />
              </div>
              <div className="text-container">
                <span className="title">Empieza a “zircular”</span>
                <span className="description">
                  Podréis compartir, alquilar, vender o regalar entre vosotros
                  aquellas cosas a las que queráis dar una segunda vida.
                </span>
              </div>
            </div>
            <div className="arrow-container">
              <img src={getArrowFunciona()} />
            </div>
            <div className="step-container">
              <div className="img-container">
                <img src={Globe2} />
              </div>
              <div className="text-container">
                <span className="title">
                  Contribuye a mejorar el medio ambiente
                </span>
                <span className="description">
                  Podréis compartir, alquilar, vender o regalar entre vosotros
                  aquellas cosas a las que queráis dar una segunda vida.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          ref={beneficiosRef}
          className="conoce-nuestros-beneficios-container"
        >
          <span className="title">¡Conoce nuestros Beneficios!</span>
          <div className="beneficio-container empresa">
            <img src={BenefitsCorporation} />
            <div className="text">
              <span className="title">Empresa</span>
              <span className="description">
                Alinea tu estrategia empresarial con los ODS y atrae a
                inversores y consumidores que buscan empresas más responsables
              </span>
              <span className="description">
                Involucra a los empleados en un proyecto común fomentando su
                participación, compromiso y orgullo de pertenencia a una empresa
                sostenible
              </span>
            </div>
          </div>
          <div className="beneficio-container empleados">
            <img src={BenefitsEmployee} />
            <div className="text">
              <span className="title">Empleados</span>
              <span className="description">
                Ofrece la posibilidad de acceder a un marketplace seguro donde
                los compañeros de trabajo puedan vender, alquilar, compartir o
                regalar artículos entre ellos
              </span>
              <span className="description">
                Incrementa el engagement entre tus empleados
              </span>
            </div>
          </div>
          <div className="beneficio-container">
            <img src={BenefitsPlanet} />
            <div className="text">
              <span className="title">Planeta</span>
              <span className="description">
                Fomenta modelos de producción y consumo basados en la
                reutilización minimizando el consumo de recursos naturales.
              </span>
              <span className="description">
                Contribuye a reducir el impacto medio ambiental reduciendo la
                huella de carbono
              </span>
            </div>
          </div>
          <div className="saved-container">
            <span className="title">
              Estos son los diferentes niveles a los que una empresa puede
              llegar:
            </span>
            <div className="items-container">
              <div className="item-container">
                <img src={Level1} />
                <span className="number-saved">400</span>
                <span className="description">Total de C02 ahorrado</span>
              </div>
              <div className="item-container">
                <img src={Level2} />
                <span className="number-saved">3.400</span>
                <span className="description">Total de C02 ahorrado</span>
              </div>
              <div className="item-container">
                <img src={Level3} />
                <span className="number-saved">15.700</span>
                <span className="description">Total de C02 ahorrado</span>
              </div>
              <div className="item-container">
                <img src={Level4} />
                <span className="number-saved">45.800</span>
                <span className="description">Total de C02 ahorrado</span>
              </div>
            </div>
          </div>
        </div>
        <div ref={principiosRef} className="nuestros-principios-container">
          <span className="title">Nuestro propósito</span>
          <span className="description">
            Hemos nacido para convertirnos en la plataforma de las empresas para
            impulsar la economía circular entre los empleados
          </span>
          <div className="img-list-container">
            <div className="img-container">
              <img src={Principle1} />
            </div>
            <div className="img-container">
              <img src={Principle2} />
            </div>
            <div className="img-container">
              <img src={Principle3} />
            </div>
            <div className="img-container">
              <img src={Principle4} />
            </div>
            <div className="img-container">
              <img src={Principle5} />
            </div>
          </div>
          <div className="text-carrousel">
            <div
              className={`text-container ${
                isTextCarrouselAnimating ? 'animating' : ''
              }`}
            >
              <span className="title">
                {principles[currentPrinciple].title}
              </span>
              <span className="description">
                {principles[currentPrinciple].description}
              </span>
            </div>
            <div className="navigation-container">
              <div
                className="arrow-container"
                onClick={() => {
                  setTimeout(() => updatePrinciple(Operation.SUBTRACT), 250);
                  activateTextCarrouselAnimation();
                }}
              >
                <img src={LeftGreen} />
              </div>
              <span className="counter-text">
                {currentPrinciple + 1 + '/' + 5}
              </span>
              <div
                className="arrow-container"
                onClick={() => {
                  setTimeout(() => updatePrinciple(Operation.ADD), 250);
                  activateTextCarrouselAnimation();
                }}
              >
                <img src={RightGreen} />
              </div>
            </div>
          </div>
        </div>
        <div className="confia-empresa-container">
          <div className="confia-empresa-text-container">
            <span className="text">
              Empresas que confían en nosotros para mejorar el mundo
            </span>
            <div className="brands-container">
              <img src={RPG} />
            </div>
          </div>
          {getTrustCompanyImage()}
        </div>
        <div ref={contactoRef} className="contacta-container">
          <div className="left-container">
            <span className="title">
              Estamos encantados de responder a tus preguntas y de que quieras
              formar parte de la familia Wezircle.
            </span>
            <span className="description">
              Descubre cómo puedes impulsar la economía circular en tu empresa.
              Obtén información sobre precios. Conoce como tu comunidad puedes
              ayudar a reducir la emisión de la Huella medio ambiental.
            </span>
            <div className="contacto-field-container">
              <div className="icon-container">
                <img src={TelephoneWhite} />
              </div>
              <div className="text-container">
                <span className="title">Teléfono de contacto</span>
                <span className="description">+34 623 998 446</span>
              </div>
            </div>
            <div className="contacto-field-container">
              <div className="icon-container">
                <img src={MailWhite} />
              </div>
              <div className="text-container">
                <span className="title">Mail de contacto</span>
                <span className="description">contact@wezircle.com</span>
              </div>
            </div>
          </div>
          <div className="right-container"></div>
        </div>
        <div className="footer-links-container">
          <img src={WezircleWhite} />
          <div className="links-container-list">
            <div className="links-container">
              <span className="title">Wezircle</span>
              <span
                className="link"
                onClick={() => creaZircleRef.current?.scrollIntoView()}
              >
                Quiénes somos
              </span>
              <span
                className="link"
                onClick={() => comoFuncionaRef.current?.scrollIntoView()}
              >
                Cómo funciona
              </span>
              <span
                className="link"
                onClick={() => beneficiosRef.current?.scrollIntoView()}
              >
                Beneficios
              </span>
              <span
                className="link"
                onClick={() => contactoRef.current?.scrollIntoView()}
              >
                Contacto
              </span>
              <span
                className="link"
                onClick={() => principiosRef.current?.scrollIntoView()}
              >
                Propósitos
              </span>
            </div>
            <div className="links-container">
              <span className="title">Legal</span>
              <span
                className="link"
                onClick={() =>
                  window.open(
                    `https://wezircle.s3.eu-west-3.amazonaws.com/privacy.pdf`,
                    '_blank'
                  )
                }
              >
                Aviso legal
              </span>
              <span
                className="link"
                onClick={() =>
                  window.open(
                    `https://wezircle.s3.eu-west-3.amazonaws.com/privacy.pdf`,
                    '_blank'
                  )
                }
              >
                Condiciones de uso
              </span>
              <span
                className="link"
                onClick={() =>
                  window.open(
                    `https://wezircle.s3.eu-west-3.amazonaws.com/privacy.pdf`,
                    '_blank'
                  )
                }
              >
                Política privacidad
              </span>
              <span
                className="link"
                onClick={() =>
                  window.open(
                    `https://wezircle.s3.eu-west-3.amazonaws.com/privacy.pdf`,
                    '_blank'
                  )
                }
              >
                Política de Cookies
              </span>
            </div>
          </div>
        </div>
        <div className="footer-copyright-container">
          <span className="copyright-text">
            Copyright © 2023 Wezircle © de sus respectivos propietarios
          </span>
        </div>
      </div>
    </>
  );
};

export default Landing;
